import React, { useState } from 'react';
import '@google/model-viewer/dist/model-viewer';

const AtelierModel3DViewer = props => {
  const { url, name } = props;
  const modelRef = React.useRef();
  const [annots, setAnnots] = useState([]);

  const handleClick = event => {
    const { clientX, clientY } = event;

    if (modelRef.current) {
      const hit = modelRef.current.positionAndNormalFromPoint?.(clientX, clientY);
      if (hit) {
        setAnnots(annots => {
          return [...annots, hit];
        });
      }
    }
  };

  const getDataPosition = annot => {
    return `${annot.position.x} ${annot.position.y} ${annot.position.z}`;
  };

  const getDataNormal = annot => {
    return `${annot.normal.x} ${annot.normal.y} ${annot.normal.z}`;
  };

  return (
    <model-viewer
      class='tile__player'
      src={url}
      loading='eager'
      camera-controls
      onClick={handleClick}
      ref={ref => {
        modelRef.current = ref;
      }}
      ar={true}
    >
      <div className='model-button-load button-primary btn-large' slot='poster'>
        Loading {name}
      </div>
      {/* <div className='progress-bar hide' slot='progress-bar'>
        <LoadingAlert></LoadingAlert>
      </div> */}

      {annots.map((annot, idx) => (
        <button
          key={`hotspot-${idx}`}
          className='view-button'
          slot={`hotspot-${idx}`}
          data-position={getDataPosition(annot)}
          data-normal={getDataNormal(annot)}
        ></button>
      ))}
    </model-viewer>
  );
};

export default AtelierModel3DViewer;
